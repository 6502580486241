.profile-content {
    margin: 0 auto;
    padding: 20px;
    /* Add padding for smaller devices */
    margin-top: 100px;
}

body {
    /* background: url('/public/bg-img-5_upscayl.png') no-repeat; */
    background-color: #dee2e6;
    background-size: cover;
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.profile-content {
    width: auto;
    height: auto;
    /* box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .1);
    border-radius: 5px;
    background-color: rgba(36, 35, 35, 0.15);
    backdrop-filter: blur(5px); */
    margin-top: 8%;
}

.card-title {
    font-size: 1.5rem;
    /* Responsive font size */
}

.welcome-message {
    font-size: 1.2rem;
    margin-bottom: 200px;
}

.report-section {
    font-weight: bold;
    font-size: 1.8rem;
    margin-top: 200px;
}

.report-title-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 5px;
}


/* Responsive styles */
@media (max-width: 768px) {
    .card-title {
        font-size: 1.3rem;
        /* Adjust for mobile */
    }

    .welcome-message {
        font-size: 1rem;
        /* Adjust for mobile */
    }

    .report-section {
        font-size: 1.5rem;
        /* Adjust for mobile */
    }

    .d-flex {
        flex-direction: column;
        /* Stack cards vertically on small screens */
        align-items: center;
    }

    .gap-4 {
        gap: 20px;
        /* Reduced gap for mobile */
    }
}