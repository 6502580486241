/* logo  */
.logo {
    width: 80px;
    height: 50px;
}

/* navbarStyle class */
.navbarStyle {
    padding: 10px 20px;
    /* Adjust padding as necessary */
    position: fixed;
    /* Make sure it stays at the top */
    top: 0;
    /* Ensure it is at the very top */
    left: 0;
    /* Align to the left */
    width: 100%;
    /* Ensure it spans the full width */
    z-index: 1000;
    /* Keep it above other content */
    margin: 0;
    /* Remove default margin */
}

.scroll-content::first-letter {
    font-size: 30px;
    color: red;
}

::selection {
    background-color: #367491;
}

.card-title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
}

.welcome-message {
    font-size: 16px;
}

.report-section {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}


/* Remove scrollbars */
body,
html {
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* This will hide scroll bars */
    height: 100%;
}

/* Fullscreen image container */
.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    /* Optional background color */
}

/* Make the image full width and centered */
.full-width-image {
    width: 70%;
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    /* Ensure the image covers the space */
}

  
  /* General button styling */
  .btn-sign-in, .btn-sign-out {
    background-color: #f80f07; /* Set background color to red */
    color: white;              /* Change text color to white for contrast */
    border: none;              /* Remove border */
    padding: 10px 20px;       /* Add some padding for better look */
    border-radius: 5px;       /* Optional: round the corners */
    cursor: pointer;           /* Change cursor to pointer on hover */
}

/* Hover effect */
.btn-sign-in, .btn-sign-out:hover {
    background-color: #ef233c; /* Darker red on hover */
}


@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        height: auto;
        /* Auto height for smaller screens */
    }

    .main-layout {
        flex-direction: column;
        /* Stack sidebar and content */
    }
}


/* Responsive styles for tablet and mobile screens */
#cards-container {
    width: 100%;
    overflow-x: hidden; /* Default to hidden to prevent horizontal overflow */
    max-height: 400px; /* Set a maximum height for the card container */
}

@media (max-width: 1024px) {
    #cards-container {
        display: flex;
        flex-wrap: nowrap; /* Keep cards on the same line for tablet */
        overflow-x: auto; /* Enable horizontal scrolling for tablet */
        padding: 20px;
    }

    #cards-container::-webkit-scrollbar {
        height: 8px; /* Adjust the height of the scrollbar */
    }

    #cards-container::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3); /* Custom scrollbar thumb color */
        border-radius: 5px;
    }

    /* Use class selector for card elements */
    .card {
        min-width: 250px; /* Smaller minimum width for cards */
        max-width: 300px; /* Set a maximum width for cards */
        margin-right: 15px; /* Space between the cards */
        flex-shrink: 0; /* Prevent the card from shrinking */
    }

    /* Optional: Remove margin from the last card */
    .card:last-child {
        margin-right: 0; /* Remove margin from the last card */
    }
}

@media (max-width: 600px) {
    #cards-container {
        overflow-y: auto; /* Enable horizontal scrolling */
        white-space: nowrap; /* Prevent cards from wrapping to the next line */
        padding: 10px; /* Optional: add some padding for aesthetics */
    }
    
    .card {
        display: inline-block; /* Ensure cards are displayed inline */
        margin-right: 10px; /* Space between cards */
    }
    

    .user-name {
        font-size: 10px;
        margin: 0 5px; /* Add some margin for spacing */
    }
    
    #btn-welcome {
        display: flex; /* Enable flexbox */
        flex-direction: row; /* Keep items in a row for mobile */
        align-items: center; /* Vertically center items */
        justify-content: space-between; /* Space between items */
    }
    
    .user-name {
        margin-bottom: 5px; /* Add margin below username */
    }
    
    #logout-btn, #login-btn  {
        width: 80px; /* Width for the logout button */
        height: 30px; /* Height for the logout button */
        font-size: 10px; /* Font size for the logout button */
        display: flex; /* Use flexbox to center text */
        align-items: center; /* Vertically center text */
        justify-content: center; /* Horizontally center text */
        text-align: center; /* Center text */
    }


    .image-container img {
        width: 100%;
        height: auto;
        display: block;
    }
    
    
    .logo {
        width: 30px;
        height: 20px;
        margin-right: 5px; /* Add space between logo and username */
    }

    .card {
        width: 100%; /* Full width for mobile cards */
        margin: 10px 0; /* Margin between cards */
    }

    /* Custom scrollbar styles for mobile */
    #cards-container::-webkit-scrollbar {
        width: 8px; /* Width of vertical scrollbar */
    }

    #cards-container::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3); /* Custom scrollbar thumb color */
        border-radius: 5px; /* Round corners for the scrollbar thumb */
    }

    #cards-container::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1); /* Background of the scrollbar track */
        border-radius: 5px; /* Round corners for the scrollbar track */
    }
}





